<template>
  <div>
    <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-10" v-if="showModal"></div>
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="absolute top-20 mx-auto p-5 border w-full shadow-lg rounded-md bg-white z-20" v-if="showModal">
        <div class="mt-3 text-center">
          <h3 class="text-lg font-medium font-gothic text-black text-2xl">Join Table</h3>
          <div class="space-y-4 mt-6">
            <form @submit="submit">
              <input type="text" class="focus:ring-red-500 focus:border-red-500 px-4 py-4 rounded-xl border border-gray-200 w-full mt-4" placeholder="Table Code" v-model="code" ref="code">
              <p class="text-sm text-red-500 pt-1 text-left" v-show="error">{{ error }}</p>
              <button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg mt-4">
                Join Now
              </button>
            </form>
            <button class="font-gothic text-gray-400 pt-3" @click="$emit('closeModal')">Cancel</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/javascript">
  export default {
    props: {
      showModal: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        code: '',
        error: null
      }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal');
        },
        validate(){
            if (this.code.length !== 4){
              this.error = "Table codes are 4 characters long";
              return false;
            }
            return true;
        },
        async submit(){
            if (!this.validate()) return;
            await this.$store.dispatch('table/joinTable', this.code).then( r => {
                this.closeModal();
                this.$router.push({
                    name: 'table',
                    params: {
                        uuid: r.uuid
                    }
                })
            }).catch( () => {
              this.error = "You are not able to join that table";
            });
        }
    }
  }
</script>