<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showMenu="true" :showBell="true"/>
		<AppTitle title="Dashboard"/>
    <ModalJoinTable @closeModal="joinTableModalOpen = false" :showModal="joinTableModalOpen"/>
    <div class="flex space-x-6 mt-4">
      <a href="#" @click.stop.prevent="activeTab = 'open'" class="font-bold text-lg" :class="activeTab === 'open' ? 'text-red-500' : 'text-gray-300'">Open</a>
      <a href="#" @click.stop.prevent="activeTab = 'history'" class="font-bold text-lg" :class="activeTab === 'history' ? 'text-red-500' : 'text-gray-300'">History</a>
      <a href="#" @click.stop.prevent="activeTab = 'restaurants'" class="font-bold text-lg" :class="activeTab === 'restaurants' ? 'text-red-500' : 'text-gray-300'"><font-awesome-icon icon="fa-solid fa-star"/> Restaurants</a>
    </div>
		<div class="pt-8 overflow-y-scroll flex-grow">
      <div v-show="tables.length == 0" class="border-dashed border-2 border-gray-200 rounded-lg py-6 px-6 text-center text-gray-400 text-sm mb-8">
        <font-awesome-icon icon="fa-solid fa-mug-hot" class="text-4xl pb-4 pt-6"/>
        <p>You don't have recent activity yet</p>
        <p>To get started join or create a table</p>
        <div class="grid grid-cols-2 gap-4 pt-8">
          <div>
            <button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="joinTableModalOpen=true">
              Join Table
            </button>
          </div>
          <div>
            <button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="createNewTable()">
              New Table
            </button>
          </div>
        </div>
      </div>

      <div v-show="tables.length && ['open','history'].includes(activeTab)">
        <router-link :to="{ name: 'table', params: { uuid: table.uuid } }" v-for="table in tables" :key="table.id">
          <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4" >
              <div class="flex-1">
                <p class="pb-1 font-bold"><span>{{ table.name }}</span> <span class="text-gray-400 pl-2 text-sm">{{table.code.toUpperCase()}}</span></p>
                <div v-if="table.my_answers.status === 'invited'">
                  <font-awesome-icon icon="fa-solid fa-envelope-open-text" class="text-red-500 mr-1"/> Invitation open
                </div>
                <div v-else>
                  <div v-if="table.status === 'incomplete'">
                    <font-awesome-icon icon="fa-solid fa-mug-hot" class="text-red-500 mr-1"/> Awaiting preferences
                  </div>
                  <div v-if="table.status === 'done'">
                    <font-awesome-icon icon="fa-solid fa-check" class="text-red-500 mr-1"/> Complete
                  </div>
                  <div v-if="table.status === 'processing'">
                    <font-awesome-icon icon="fa-solid fa-clock" class="text-red-500 mr-1"/> Processing results
                  </div>
                  <div v-if="table.status === 'error'">
                    <font-awesome-icon icon="fa-solid fa-circle-question" class="text-red-500 mr-1"/> Something went wrong
                  </div>
                </div>
              </div>
              <div class="flex-none">
                <button class="bg-red-500 w-8 h-8 rounded-lg" @click.stop.prevent="tableActions(table)">
                  <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="text-white"/>
                </button>
              </div>
          </div>
        </router-link>
      </div>

      <div v-show="activeTab === 'restaurants'">
        <router-link 
          :to="{ name: 'restaurant', params: { restaurant_uuid: restaurant.uuid } }"
          v-for="restaurant in restaurants"
          :key="restaurant.uuid"
        >
          <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4" >
              <div class="flex-1">
                <p class="pb-1 font-bold">{{ restaurant.name }}</p>
                <p>{{ restaurant.formatted_address }}</p>
              </div>
              <div class="flex-none">
                <button class="bg-red-500 w-8 h-8 rounded-lg">
                  <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="text-white"/>
                </button>
              </div>
          </div>
        </router-link>
      </div>
		</div>
    <div v-show="tables.length" class="grid grid-cols-2 gap-4 w-full pt-8 pb-8 bg-gray-50">
      <div>
        <button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="joinTableModalOpen=true">
          Join Table
        </button>
      </div>
      <div>
        <button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="createNewTable()">
          New Table
        </button>
      </div>
    </div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
  import ModalJoinTable from '@/components/modals/ModalJoinTable.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
      ModalJoinTable
		},
    data(){
      return {
        joinTableModalOpen: false,
        activeTab: 'open'
      }
    },
    mounted(){
      this.$store.dispatch('table/getTables');
    },
    computed: {
      tables(){
        let tables = this.$store.state.table.tables;
        if (this.activeTab === 'open'){
          return tables.filter( table => table.status === 'incomplete' );
        }
        return tables.filter( table => table.status !== 'incomplete' );
      },
      restaurants(){
        return this.$store.state.restaurants.favorites;
      },
      user(){
        return this.$store.state.auth.user;
      },
    },
    methods: {
      tableActions(table){
        let buttons = [];
        // console.log(table);
        if (table.my_answers.status !== 'invited') {
          buttons.push({
            label: 'Chat',
            icon: 'fa-solid fa-comment',
            action: 'function',
            payload: () => {
              this.$router.push({
                name: 'chat',
                params: {
                  uuid: table.uuid
                }
              });
            }
          });
        }
        if (table.user_id === this.user.id) {
          buttons.push({
            label: 'Delete',
            icon: 'fa-solid fa-trash-can',
            action: 'function',
            payload: () => {
              this.$store.dispatch('table/deleteTable', {
                uuid: table.uuid
              }).then( () => {
                this.$store.dispatch('table/getTables');
              });
            }
          });
        } else {
          buttons.push({
            label: 'Leave Table',
            icon: 'fa-solid fa-person-running',
            action: 'function',
            payload: () => {
              this.$store.dispatch('table/removeFromTable', {
                table: table.uuid,
                user: this.user.uuid
              }).then( () => {
                this.$store.dispatch('table/getTables');
              });
            }
          });
        }
        this.$store.commit('scrollUpMenu', {
          buttons
        });
      },
      createNewTable(){
        this.$store.dispatch('table/createTable').then( r => {
          if (r && r.data && r.data.uuid){
            this.$router.push({
              name: 'table',
              params: {
                uuid: r.data.uuid
              }
            });
            this.$store.dispatch('table/getTables');
          }
        })
      }
    }
	}
</script>